/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import { rem } from "@src/theme";

const SocialShare = ({ description, url }) => {
  const links = [
    {
      type: "Facebook",
      href: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    },
    {
      type: "Twitter",
      href: `https://twitter.com/intent/tweet?&url=${url} ${description}`,
    },
    {
      type: "Email",
      href: `mailto:info@example.com?&subject=&body=${url} ${description}`,
    },
  ];

  return (
    <div
      sx={{
        bg: "stream",
        mb: rem(50),
        mt: [rem(20), null, rem(30)],
      }}
    >
      <section
        sx={{
          mx: "auto",
          width: ["95%", "65%", "65%"],
          bg: "stream",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          flexDirection: ["column", "row"],
          alignItems: "center",
          py: rem(12),
        }}
      >
        <h2
          sx={{
            fontSize: rem(20),
            fontWeight: "300",
            my: 0,
            fontFamily: "body",
            transform: ["none", null, "translateY(-1px)"],
          }}
        >
          Share this article
        </h2>
        <React.Fragment>
          {links.map(({ type, href }) => (
            <a
              href={href}
              sx={{
                variant: "typography.link",
                color: "coal",
                display: ["none", "inline-block"],
                letterSpacing: "-0.25px",
              }}
            >
              {type}
            </a>
          ))}

          <div
            sx={{
              display: ["block", "none"],
            }}
          >
            {links.map(({ type, href }) => (
              <a
                href={href}
                sx={{
                  fontFamily: "heading",
                  letterSpacing: "-0.25px",
                  color: "coal",
                  fontSize: rem(16),
                  mr: rem(20),
                  "&:last-child": {
                    mr: 0,
                  },
                }}
              >
                {type}
              </a>
            ))}
          </div>
        </React.Fragment>
      </section>
    </div>
  );
};

export default SocialShare;
