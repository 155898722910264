/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import striptags from "striptags";
import { Grid, Cell } from "@components/primitives/Grid";
import { rem } from "@src/theme";
import ArticleListingItem from "@components/ArticleListingItem";
import TagCloud from "@src/components/TagCloud";
import { graphql, Link } from "gatsby";
import { mapCraft } from "./mappings";
import Layout from "@components/layouts";
import React from "react";
import SEO from "@src/components/seo";
import SocialShare from "@src/components/SocialShare";
import { Section, SectionBlock } from "@components/ContentBlocks";
import Masthead from "@components/Masthead";
import ButtonLink from "@src/components/ButtonLink";

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const Contributors = ({ contributors, type }) =>
  contributors.length ? (
    <div
      sx={{
        fontFamily: "article",
        mt: type === "authors" ? rem(13) : 0,
        fontSize: "18px",
      }}
    >
      {
        {
          authors: "Words: ",
          photographers: "Images: ",
        }[type]
      }
      {contributors.length === 2 ? (
        <React.Fragment>
          <Link
            to={`/tags/${contributors[0].slug}`}
            sx={{
              fontFamily: "heading",
              color: "black",
            }}
          >
            {contributors[0].title}
          </Link>{" "}
          &{" "}
          <Link
            to={`/tags/${contributors[1].slug}`}
            sx={{
              fontFamily: "heading",
              color: "black",
            }}
          >
            {contributors[1].title}
          </Link>
        </React.Fragment>
      ) : (
        contributors.map(({ title, slug }, index) => (
          <React.Fragment>
            <Link
              to={`/tags/${slug}`}
              sx={{
                fontFamily: "heading",
                color: "black",
              }}
            >
              {title}
            </Link>
            {contributors.length !== 1 &&
              index !== contributors.length - 1 &&
              ","}
          </React.Fragment>
        ))
      )}
    </div>
  ) : null;

const ArticleTemplate = (props) => {
  const { entry, relatedArticles } = mapCraft(props.data.craft);
  const [socialHref, setSocialHref] = useState(null);
  useEffect(() => {
    setSocialHref(props.location.href);
  }, [props.location.href]);
  const {
    heroImage,
    title,
    date,
    introductoryText,
    sections,
    tagDuo: tagDuoItems,
    tags,
    authors,
    photographers,
    featuredArticle,
    primaryTopic,
  } = entry;

  const strippedIntroText = striptags(introductoryText);

  const nextArticle = featuredArticle
    ? {
        title: featuredArticle.title,
        url: `/articles/${featuredArticle.slug}`,
        tagDuo: [
          featuredArticle.contentType[0].title,
          featuredArticle.primaryTopic[0].title,
        ],
        introText: striptags(featuredArticle.introductoryText),
        img: featuredArticle.heroImage[0]
          ? featuredArticle.heroImage[0].url
          : null,
      }
    : null;

  const moreInArticles = relatedArticles.filter(
    (article) => article.title !== title
  );
  shuffle(moreInArticles);

  return (
    <Layout fixedSidebar={true} location={props.location}>
      <SEO
        location={props.location}
        defaultTitle={title}
        type="article"
        metadata={{
          metaDescription: strippedIntroText,
          socialImage: heroImage ? heroImage.url : "",
        }}
      />

      <Masthead
        introductoryText={introductoryText}
        title={title}
        heroImage={heroImage}
        tags={tagDuoItems}
        date={date}
      />

      <Grid>
        <Section>
          <div
            sx={{
              mb: rem(80),
            }}
          >
            {authors.length ? (
              <Contributors contributors={authors} type="authors" />
            ) : null}

            {photographers.length ? (
              <Contributors contributors={photographers} type="photographers" />
            ) : null}
            <p
              sx={{
                variant: "typography.bodyMedium",
                mt: rem(10),
              }}
            >
              Contribute to support more positive local storytelling.
            </p>

            <ButtonLink href="https://dashboard.presspatron.com/donations/new?frame=1&t=wCUc2iNiCxZJTJjECwEcYDwN">
              Support
            </ButtonLink>
          </div>
        </Section>
      </Grid>

      {sections.map((section) => (
        <SectionBlock {...section} />
      ))}

      <Grid>
        <Section>
          <div
            sx={{
              mt: rem(50),
            }}
          >
            <TagCloud
              tags={tags.filter(
                ({ tagType, slug }) =>
                  tagType !== "contributor" && slug !== "contributors"
              )}
            />
          </div>

          <SocialShare description={strippedIntroText} url={socialHref} />
          {featuredArticle && (
            <React.Fragment>
              <Cell gridColumn={["1 / -1", null, "1 / span 10"]}>
                <hr
                  sx={{
                    bg: "coal",
                    height: "1px",
                    border: "none",
                  }}
                />
              </Cell>
              <Cell
                gridColumn={["1 / -1", null, "1 / span 10"]}
                styles={{
                  mb: rem(80),
                }}
              >
                <Styled.h3
                  sx={{
                    mt: rem(16),
                  }}
                >
                  Read this next
                </Styled.h3>

                <ArticleListingItem {...nextArticle} lgHeading />
              </Cell>
            </React.Fragment>
          )}

          {relatedArticles && relatedArticles.length ? (
            <React.Fragment>
              <Cell gridColumn={["1 / -1", null, "1 / span 10"]}>
                <hr
                  sx={{
                    bg: "coal",
                    height: "1px",
                    border: "none",
                  }}
                />
              </Cell>
              <Cell gridColumn={["1 / -1", null, "1 / span 10"]}>
                <Styled.h3
                  sx={{
                    mt: rem(16),
                    variant: "typography.headingLarge!important",
                  }}
                >
                  More in{" "}
                  <Link
                    sx={{
                      color: "black",
                      letterSpacing: "-0.25px",
                    }}
                    to={`/tags/${primaryTopic.slug}`}
                  >
                    {primaryTopic.title}
                  </Link>
                </Styled.h3>

                <Grid gridRowGap={[rem(16), null, rem(96)]}>
                  {moreInArticles[1] && (
                    <Cell gridColumn={["1 / -1", "1 / span 8"]}>
                      <ArticleListingItem
                        {...moreInArticles[0]}
                        gridItem={true}
                      />
                    </Cell>
                  )}
                  {moreInArticles[1] && (
                    <Cell gridColumn={["1 / -1", "9 / span 8"]}>
                      <ArticleListingItem
                        {...moreInArticles[1]}
                        gridItem={true}
                      />
                    </Cell>
                  )}
                </Grid>
              </Cell>
            </React.Fragment>
          ) : null}
        </Section>
      </Grid>
    </Layout>
  );
};

export default ArticleTemplate;

export const query = graphql`
  query ArticlesQuery($id: Craft_QueryArgument) {
    craft {
      entry(id: [$id]) {
        ...articleFields
      }

      articles: entries(section: "articles") {
        ...articleGridFields
      }
    }
  }
`;
