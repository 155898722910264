/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "@src/theme";
import styles from "./styles";
import { Link } from "gatsby";

const Tag = ({ children, url }: { children: React.ReactNode; url: string }) => (
  <Link
    to={url}
    sx={{
      letterSpacing: "-0.25px",
      ...styles.listItem,
    }}
  >
    {children}
  </Link>
);

interface ComponentProps {
  tags: Array<{
    title: string;
    slug: string;
  }>;
}

const TagCloud = ({ tags }: ComponentProps) =>
  tags && tags.length ? (
    <div
      sx={{
        display: "flex",
        flexDirection: ["column", null, "row"],
        alignItems: ["flex-start", null, "center"],
      }}
    >
      <p
        sx={{
          mt: 0,
          marginRight: rem(12),
          fontSize: rem(20),
          mb: [rem(8)],
          transform: ["none", null, "translateY(-2px)"],
        }}
      >
        More in
      </p>
      <ul sx={styles.list}>
        {tags.map((tag) => (
          <Tag url={`/tags/${tag.slug}`}>{tag.title}</Tag>
        ))}
      </ul>
    </div>
  ) : null;

export default TagCloud;
