import striptags from "striptags";
import moment from "moment";

const mapEntry = (entry) => {

  if (entry) {
    return {
      title: entry.title,
      date: moment(entry.date, "YYYY-MM-DD").isValid
        ? moment(entry.date, "YYYY-MM-DD").format("ll")
        : entry.date,
      tagDuo: [entry.contentType[0], entry.primaryTopic[0]],
      tags: entry.tags,
      authors: entry.wordsBy,
      photographers: entry.imagesBy,
      heroImage: entry.heroImage[0] || null,
      introductoryText: entry.introductoryText,
      featuredArticle: entry.featuredArticle[0] || null,
      primaryTopic: entry.primaryTopic[0] || null,
      sections: entry.bodySections.map(({ __typename, ...props }) => {
        switch (__typename) {
          case "Craft_bodySections_html_BlockType":
            return {
              type: __typename,
              content: props.body,
            };
          case "Craft_bodySections_gallery_BlockType":
            const { gallery } = props;
            return {
              type: __typename,
              content: {
                img1: {
                  url: gallery[0].image[0].url,
                },
                img2: gallery[1]
                  ? {
                      url: gallery[1].image[0].url,
                    }
                  : null,
                img3: gallery[2]
                  ? {
                      url: gallery[2].image[0].url,
                    }
                  : null,
              },
            };
          case "Craft_bodySections_imageCaption_BlockType":
            return {
              type: __typename,
              content: {
                url: props.image[0].url,
                caption: props.caption,
              },
            };
        }
      }),
    };
  }
    return {}
  

};

const mapRelatedArticles = (articles, primaryTag) => {
  return articles
    .filter((article) => {
      if (article.primaryTopic && article.primaryTopic.length) {
        return article.primaryTopic[0].slug === primaryTag;
      }
      return false;
    })
    .map((article) => ({
      title: article.title,
      img: article.thumbnail[0]
        ? article.thumbnail[0].url
        : article.heroImage[0]
        ? article.heroImage[0].url
        : null,
      introText: article.introductoryText
        ? striptags(article.introductoryText)
        : null,
      url: `/articles/${article.slug}`,
      tagDuo: [article.contentType[0].title, article.primaryTopic[0].title],
    }));
};

export const mapCraft = (craft) => {
  // const moreResources = craft.resources.filter(resource => resource.id !== craft.entry.id)
  return {
    entry: mapEntry(craft.entry),
    relatedArticles: craft.entry.primaryTopic[0]
      ? mapRelatedArticles(
          craft.articles.filter(({ slug }) => slug !== craft.entry.slug),
          craft.entry.primaryTopic[0].slug
        )
      : null,
  };
};
