import { rem } from "@src/theme";

export default {
  list: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    a: {
      marginRight: rem(8),
      mb: [rem(8)],
      "&:last-child": {
        mr: 0,
      },
    },
  },

  listItem: {
    variant: "typography.headingSmall",
    bg: "smoke",
    p: rem(5),
    px: rem(12),
    "&:hover": {
      bg: "rgba(0, 0, 0, 0.1)",
      transform: "translateY(-1px)",
    },
    color: "currentColor",
  },
};
